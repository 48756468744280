import React from "react";

import styles from "./Layout.module.scss";

import Hero from "../Hero/Hero";
import About from "../About/About";
import Projects from "../Projects/Projects";
import Contact from "../Contact/Contact";

const Layout = () => {
  return (
    <div className={styles.layout}>
      <Hero />
      <About />
      <Projects />
      <Contact />
    </div>
  );
};

export default Layout;
