import React from 'react';

import snowDemo from '../../assets/video/Snow-screenshot-optimize.gif';
import foodieDemo from '../../assets/video/foodie3.gif';

import styles from './Projects.module.scss';
import globalStyle from '../../Style.module.scss';
import Project from './project/project';

const Projects = () => {
  return (
    <section id={'projects'} className={styles.projects}>
      <h2 className={globalStyle.sectionHeader}>Project</h2>
      <div className={styles.project}>
        <img src={snowDemo} alt="snow screenshot" />
        <Project
          title="Show Now (SNow)"
          description={`Find out the most popular movies, tv shows, and persons in the media
        world. You can also search for a specific title or just search based
        on genre, year, revenue, etc. This way, you can easily find what you
        want to watch next.`}
          techUsed={[
            'ReactJS',
            'React Router',
            'SASS',
            'AJAX',
            'Rest API (The Movie Database API)',
          ]}
          projectLink="http://pramitbarua.com/project/snow/"
          githubLink="https://github.com/PramitBarua/SNow"
        />
      </div>
      {/* foodie */}
      <div className={styles.project}>
        <Project
          title="Foodie"
          description={`Foodie is a recipe search app to find food recipes, ingredients, time, and many more`}
          techUsed={['ReactJS', 'SASS', 'redux', 'jest', 'enzyme', 'rest API']}
          projectLink="http://pramitbarua.com/project/foodie/"
          githubLink="https://github.com/PramitBarua/foodie"
        />
        <img src={foodieDemo} alt="foodie screenshot" />
      </div>
    </section>
  );
};

export default Projects;
