import React from "react";

import styles from "./Hero.module.scss";
import globalStyle from "../../Style.module.scss";
import illustration from "../../assets/images/undraw_people_tax5.svg";

const Hero = () => {
  return (
    <section className={styles.hero}>
      <div className={styles.heroText}>
        <div>
          <p>Hi, I am</p>
          <h2 className={styles.emphasiseColor}>pramit barua</h2>
          <h2 className={styles.secondaryColor}>Front-end developer</h2>
        </div>
        <div>
          <h2>Let's</h2>
          <h1 className={styles.secondaryColor}>Bring your concepts to life</h1>
        </div>
        <div>
          <a className={globalStyle.linkHighlight} href="#projects">
            see Projects
          </a>
        </div>
      </div>
      <img
        className={styles.illustrationImg}
        src={illustration}
        alt="illustration"
      />
    </section>
  );
};

export default Hero;
