import React, { Component } from "react";

import { FaBars, FaWindowClose } from "react-icons/fa";

import logo from "../../assets/images/pbLogo.png";

import styles from "./Navbar.module.scss";
import globalStyle from "../../Style.module.scss";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navDropDownShow: false,
    };
    // this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  handleNavDropDownShow = () => {
    this.setState({
      navDropDownShow: true,
    });
  };

  handleNavDropDownHide = () => {
    this.setState({
      navDropDownShow: false,
    });
  };

  render() {
    let styleClass, navBtn, navDropDownClass, navBtnHandler;

    styleClass = this.props.navShow
      ? `${styles.navContainer}`
      : `${styles.navContainer}  ${styles.navContainerHide}`;
    styleClass = this.props.navPositionTop
      ? styleClass
      : styleClass + ` ${styles.navContainerBoxShadow}`;

    navBtnHandler = this.handleNavDropDownShow;
    navBtn = <FaBars className={styles.barIcon} />;
    navDropDownClass = `${styles.navDropDown} ${styles.hide}`;

    if (this.state.navDropDownShow) {
      navBtnHandler = this.handleNavDropDownHide;
      navBtn = <FaWindowClose className={styles.barIcon} />;
      navDropDownClass = `${styles.navDropDown} ${styles.show}`;
    }

    const navLinksDropDown = (
      <ul className={navDropDownClass}>
        <li>
          <a onClick={this.handleNavDropDownHide} href="#about">
            About Me
          </a>
        </li>
        <li>
          <a onClick={this.handleNavDropDownHide} href="#projects">
            Projects
          </a>
        </li>
        <li>
          <a onClick={this.handleNavDropDownHide} href="#contact">
            Contact
          </a>
        </li>
      </ul>
    );

    return (
      <>
        <div className={styleClass}>
          <img src={logo} alt="logo" className={styles.logo} />

          <ul>
            <li>
              <a className={styles.navlink} href="#about">
                About Me
              </a>
            </li>
            <li>
              <a className={styles.navlink} href="#projects">
                Projects
              </a>
            </li>
            <li>
              <a className={styles.navlink} href="#contact">
                Contact
              </a>
            </li>
          </ul>
          <a
            href="https://s3.amazonaws.com/pramitbarua.com/Resume_Pramit_Barua.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className={`${globalStyle.linkHighlight} ${styles.resume}`}
          >
            Resume
          </a>
          <button
            aria-label="menu"
            className={styles.navBtn}
            onClick={navBtnHandler}
          >
            {navBtn}
          </button>
        </div>
        {navLinksDropDown}
      </>
    );
  }
}

export default Navbar;
