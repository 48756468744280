import React from "react";

import styles from "./SkillCard.module.scss";

const SkillCard = props => {
  return (
    <div className={styles.skillCard}>
      {props.icon}
      <h3 className={styles.title}>{props.title}</h3>
      {props.skills.map((item, index) => {
        return (
          <h3 key={index} className={styles.item}>
            {item}
          </h3>
        );
      })}
    </div>
  );
};

export default SkillCard;
