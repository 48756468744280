import React from "react";
import { IconContext } from "react-icons";
import { FaEnvelope } from "react-icons/fa";

import styles from "./Contact.module.scss";
import globalStyle from "../../Style.module.scss";

const Contact = () => {
  return (
    <IconContext.Provider value={{ className: styles.icon }}>
      <section id={"contact"} className={styles.contactSection}>
        <h2 className={globalStyle.sectionHeader}>Get In Touch</h2>
        <p>
          I am looking for a fulltime position in Europe. If you have any
          project where I can contribute then, please contact me.
        </p>
        <a
          className={globalStyle.linkHighlight}
          href="mailto:pramit.barua@gmail.com"
        >
          <FaEnvelope /> {` Send an Email`}
        </a>
      </section>
    </IconContext.Provider>
  );
};

export default Contact;
