import React from 'react';
import styles from './project.module.scss';
import { FaGithub } from 'react-icons/fa';
import globalStyle from '../../../Style.module.scss';

function project(props) {
  const { title, description, techUsed, projectLink, githubLink } = props;
  return (
    <div className={styles.textDiv}>
      <h2>{title}</h2>
      <p>{description}</p>
      <ul>
        {techUsed.map((tech, index) => (
          <li key={index}>{tech}</li>
        ))}
      </ul>

      <div className={styles.linkDiv}>
        <a
          className={globalStyle.linkHighlight}
          href={`${projectLink}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Project
        </a>
        <a href={`${githubLink}`} target="_blank" rel="noopener noreferrer">
          <FaGithub className={styles.icon} />
          View Code
        </a>
      </div>
    </div>
  );
}

export default project;
