import React from "react";
import { IconContext } from "react-icons";
import { FaLinkedinIn, FaEnvelope, FaGithub } from "react-icons/fa";

import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.footerSection}>
      <p className={styles.top}>{`Design & Developed by `}</p>
      <p className={styles.bottom}>{` Pramit Barua 2020`}</p>

      <IconContext.Provider value={{ className: styles.icon }}>
        <div>
          <a href="mailto:pramit.barua@gmail.com">
            <FaEnvelope />
          </a>
          <a
            href="https://www.linkedin.com/in/pramit-barua/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn />
          </a>
          <a
            href="https://github.com/PramitBarua"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub />
          </a>
        </div>
      </IconContext.Provider>
    </div>
  );
};

export default Footer;
