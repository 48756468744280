import React, { Component } from "react";
import Navbar from "./components/Navbar/Navbar";
import Layout from "./components/Layout/Layout";
// import Construction from "./components/Construction/Construction";

import styles from "./App.module.scss";
import Footer from "./components/Footer/Footer";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navShow: true,
      navPositionTop: true,
    };
    this.scrollPosition = 0;
  }

  handleOnScroll = (e) => {
    // console.log("handleOnscroll");
    let currentPosition;
    currentPosition = e.target.scrollTop;
    console.log(currentPosition);
    // console.log(this.state);
    if (currentPosition === 0) {
      // at the top of the page
      // console.log("at the top of the page");
      this.scrollPosition = 0;
      if (!this.state.navShow || !this.state.navPositionTop) {
        this.setState({
          ...this.state,
          navShow: true,
          navPositionTop: true,
        });
      }
    } else if (currentPosition > 0 && currentPosition < 60) {
      // after 1st step scroll
      // console.log("just before top");
      this.scrollPosition = currentPosition;
      if (!this.state.navShow || this.state.navPositionTop) {
        this.setState({
          ...this.state,
          navShow: true,
          navPositionTop: false,
        });
      }
    } else if (currentPosition - this.scrollPosition > 0) {
      // scrolling down
      // console.log("scrolling down");
      this.scrollPosition = currentPosition;
      if (this.state.navShow) {
        this.setState({
          ...this.state,
          navShow: false,
          navPositionTop: false,
        });
      }
    } else {
      // scrolling up
      // console.log("scrolling up");
      this.scrollPosition = currentPosition;
      if (!this.state.navShow) {
        this.setState({
          ...this.state,
          navShow: true,
          navPositionTop: false,
        });
      }
    }
  };

  render() {
    return (
      <div onScroll={this.handleOnScroll} className={styles.App}>
        <Navbar
          navShow={this.state.navShow}
          navPositionTop={this.state.navPositionTop}
        />
        <Layout />
        <Footer />
        {/* <Construction /> */}
      </div>
    );
  }
}

export default App;
