import React from "react";
import { IconContext } from "react-icons";
import { FaDesktop, FaRegFileCode, FaTools } from "react-icons/fa";
import { GoGear } from "react-icons/go";

import SkillCard from "../SkillCard/SkillCard";

import styles from "./About.module.scss";

const About = () => {
  return (
    <IconContext.Provider value={{ className: styles.icon }}>
      <section id={"about"} className={styles.aboutSection}>
        <h2>About me</h2>
        <div className={styles.aboutPara}>
          <p>
            Hello, my name is Pramit Barua. I am a frontend developer who loves
            creating new things and challenges. Seeing ideas and concepts come
            to life is one of my favorite parts of the development process. I
            always try to produce results quickly and efficiently without losing
            the attention to pixel-scale details, user experiences, and
            scalability.
          </p>
          <p>
            I believe anything can be overcome through commitment and hard work.
            Here are some techniques that I am comfortable to work with:
          </p>
        </div>
        <div className={styles.cards}>
          <SkillCard
            icon={<FaDesktop />}
            title={`Frontend`}
            skills={[
              "HTML5",
              "CSS3/SASS",
              "JavaScript (ES6+)",
              "ReactJS",
              "Redux",
              "VueJS",
            ]}
          />
          <SkillCard
            icon={<FaRegFileCode />}
            title={`Language`}
            skills={["Python", "Matlab", "C/C++", "Java"]}
          />
          <SkillCard
            icon={<GoGear />}
            title={`Backend`}
            skills={["NodeJS", "Express", "MongoDB", "Rest API", "Postman"]}
          />
          <SkillCard
            icon={<FaTools />}
            title={`Tools`}
            skills={[
              "JIRA",
              "Figma",
              "Chrome Devtools",
              "Git/Github",
              "Inkscape",
              "GIMP",
            ]}
          />
        </div>
      </section>
    </IconContext.Provider>
  );
};

export default About;
